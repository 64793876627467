const withoutTooltip = { '.tooltip-icon': { display: 'none' } };

const label = {
    '.label': {
        'font-family': 'Roboto, sans-serif',
        'font-size': '12px !important',
        'font-weight': '700',
        'line-height': '16px',
        'margin-bottom': '12px',
        color: '#878E95',
    },
};

const withoutLabel = { '.label': { display: 'none' } };

const placeholder = {
    'font-family': 'Roboto, sans-serif',
    'font-size': '16px',
    'font-weight': '400',
    'line-height': '24px',
    color: '#878E95',
};

const input = {
    background: '#fff',
    border: '1px solid #D4DAE0',
    'border-radius': '12px',
    color: '#212121',
    height: '56px',
    ':focus': {
        'border-color': '#212121',
    },
    '::placeholder': {
        ...placeholder,
    },
    '::-webkit-input-placeholder': {
        ...placeholder,
    },
    ':-moz-placeholder': {
        ...placeholder,
    },
    '::-moz-placeholder': {
        ...placeholder,
    },
    ':-ms-input-placeholder': {
        ...placeholder,
    },
};

const errorState = {
    '.error input': {
        'border-color': '#ECA2A2',
        color: '#212121',
    },
    '.error-text': {
        color: '#D75353',
        'font-family': 'Roboto, sans-serif',
        'font-size': '12px',
        'font-weight': '700',
        'line-height': '16px',
        padding: '4px 12px',
        '.triangle': {
            display: 'none',
        },
    },
    '.error .label': {
        color: '#878E95',
    },
    '.error.not-empty .label': {
        color: '#212121',
    },
};

export const FORM_STYLES = {
    submit_button: {
        // TODO: vars for color
        'background-color': '#FCC419',
        'font-size': '16px',
        'font-weight': 'bold',
        'border-radius': '0',
        'font-family': 'Roboto,sans-serif',
        color: '#212121',
        ':hover': {
            'background-color': '#EEBD29',
        },
        '.title-icon': { display: 'none' },
    },
    card_number: {
        input: {
            ...input,
            'border-radius': '12px 12px 0 0',
        },
        '.brand-icon': { top: '15px' },
        ...withoutLabel,
        ...errorState,
    },
    expiry_date: {
        input: {
            ...input,
            'border-radius': '0 0 0 12px',
        },
        ...withoutLabel,
        ...errorState,
    },
    card_cvv: {
        input: {
            ...input,
            'border-radius': '0 0 12px 0',
        },
        ...withoutLabel,
        ...withoutTooltip,
        ...errorState,
    },
    zip_code: {
        input: { ...input },
        ...label,
        ...withoutTooltip,
        ...errorState,
    },
    additional_field: {
        margin: '1em 0',
        input: { ...input },
        ...label,
        ...withoutTooltip,
        ...errorState,
    },
};
