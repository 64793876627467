export const startRoutes = [
    {
        path: 'StartPages/StartAge',
        route: 'start-age',
    },
    {
        path: 'StartPages/StartGender',
        route: 'start-gender',
    },
    {
        path: 'StartPages/IndexPage',
        route: 'index',
    },
    {
        path: 'StartPages/StartAgeFull2',
        route: 'start-age-full2',
    },
    {
        path: 'StartPages/StartPageGeneral',
        route: 'start-page-general',
    },
    {
        path: 'StartPages/StartAgeFull3',
        route: 'start-age-full3',
    },
    {
        path: 'StartPages/StartAgeFull2Des',
        route: 'start-age-full2-des',
    },
    {
        path: 'StartPages/StartAgeFull4',
        route: 'start-age-full4',
    },
    {
        path: 'StartPages/StartAgeFull6',
        route: 'start-age-full6',
    },
];
